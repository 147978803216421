import React from 'react';
import { CustomerTable } from './CustomerTable';
import { Reminders } from './Reminders';
import { NotifyCards } from './NotifyCards';
import { CustomerInsights } from './CustomerInsights';
import { Breakdown } from './Breakdown';

export default function Index() {
  return (
    <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="flex flex-wrap w-full pt-4">
        <div className="w-full">
          <NotifyCards />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <Reminders />
            <Breakdown />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
            <div className="md:col-span-3">
              <CustomerTable />
            </div>
            <div className="md:col-span-2">
              <CustomerInsights />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
import React, { useState } from 'react';
import { Button } from "components/ui/button";
import { Fieldset } from 'components/catalyst/fieldset';
import { SearchAccountSwitch } from "components/layout/SearchAccountSwitch";
import { con, startRecording, stopRecording } from "utils/audio/audioRecorder";
import { Activity,CircleStop,Loader } from 'lucide-react'
import { formatTranscript, whisperAPI } from "utils/audio/API";
import { Textarea } from "components/ui/textarea";
import { Input } from "components/ui/input";


export function CreateVoice({ title, setTitle, content, setContent, selectedAccount, setSelectedAccount, setType, setTranscript }) {

  const [isListening, setIsListening] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false); // New state to track processing
  const [connected, setConnected] = useState(false); 

  const toggleRecording = async () => {
    if (!isListening) {
      if (!connected) {
        await con();
        setConnected(true);
      }
      startRecording();
      setIsListening(true); 
    } else {
      const wavAudioBlob = await stopRecording();
      const audioFile = new File([wavAudioBlob], 'audio.wav');
      setIsListening(false);
      setIsProcessing(true); // Set processing to true when recording stops
      await sendAudioToGPT(audioFile);
      setIsProcessing(false); // Set processing to false after processing
    }
  };

  const sendAudioToGPT = async (audioFile) => {
    const transcript = await whisperAPI(audioFile);
    const formattedTranscriptStr = await formatTranscript(transcript); 
    
    setTranscript(transcript)
    setType('voice')

    if (formattedTranscriptStr) {  
      let formattedTranscript;
      try {
        formattedTranscript = JSON.parse(formattedTranscriptStr);
      } catch (error) {
        console.error('Error parsing formatted transcript:', error);
        return;
      }
    
      const header = '🎤 ' + formattedTranscript.header;
      const description = formattedTranscript.description;

      // Set the title and content with the parsed values
      setTitle(header);
      setContent(description);
    }
  };

  // Determine the icon to display based on the current state
  const getIcon = () => {
    if (isProcessing) {
      return <Loader className="animate-spin text-white h-10 w-10 dark:invert" />;
    }
    return isListening ? <CircleStop className="text-white h-10 w-10 dark:invert" /> : <Activity className="text-white h-10 w-10 dark:invert" />;
  };

  return (
    <div>
      <SearchAccountSwitch selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} />
      <div className="grid gap-4 justify-center items-center mt-4">
        <Button
          onClick={async (e) => {
            e.preventDefault(); 
            await toggleRecording();
          }}
          className="w-14 h-14 flex items-center justify-center bg-black rounded-full border-2 border-gray-500"
        >
          {getIcon()}
        </Button>
      </div>
      {title && 
      <div className="grid gap-4 mt-4">
        <Input
          type="text"
          id="title"
          placeholder="Reminder Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Textarea
          className="p-4"
          placeholder="Type Reminder Here..."
          rows={8}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </div>
      }
    </div>
  );
}
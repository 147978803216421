import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from "react";
import useLocalStorage from 'utils/useLocalStorage';
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from 'contexts/AppContext';

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {

    const { salesAgentCD, BASE_URL, agentFirstName, agentLastName, isMobile, isAdmin, oktaId } = useContext(AppContext);

    const BASE_CHATBOT_URL = "cosailor-chatbot-backend-stage.instalily.ai"
    const API_PREFIX = "https://" 

    // Email Generation ----------------------------

    const [outputText, setOutputText] = useState('');
    const [emailLoading, setEmailLoading] = useState(false);
    const [emailFeedback, setEmailFeedback] = useState(null);
    const [lastEmailID, setLastEmailID] = useState(null);

    const generateEmail = async (selectedAccount, user_query) => {
      setEmailLoading(true)
      try {
        const payload = {
          salesagentcd: salesAgentCD, 
          custcd: selectedAccount,
          user_query: user_query,
          oktaid: oktaId
        };
    
        const response = await axios.post(`${API_PREFIX}${BASE_CHATBOT_URL}/email-api`, payload);
        if (response.status === 200 && response.data.message) {
          console.log('Email Generated',response);
          setOutputText(response.data.message)
          setEmailLoading(false)
          setEmailFeedback(null)
          setLastEmailID(response.data.message_id)
        } else {
          console.error('Failed to generate email:', response.statusText);
          setEmailLoading(false)
          setEmailFeedback(null)
        }
      } catch (error) {
        console.error('Error generating email:', error);
      }
    };

    async function handleEmailFeedback(type) {

        setEmailFeedback(type);
        if (lastEmailID){
            try {
                const response = await axios.post(API_PREFIX+BASE_CHATBOT_URL+"/feedback", {"message_id": lastEmailID, "feedback": type, "salesagentcd": salesAgentCD, "oktaid": oktaId});
            } catch (error) {
                console.error("Error in feedback post:", error);
            }
        }
    };

    // Chat Agent ---------------------------- 

    const [chatSessionId, setChatSessionId] = useLocalStorage("chatSessionId", null);

    useEffect(() => {
        if (!chatSessionId){
            const sessionId = uuidv4();
            setChatSessionId(sessionId);
        }
    }, []);

    const defaultMessage = [
        {
          type: "ai",
          text: "Hi, "+agentFirstName+" how are you? I'm here to help with any questions you may have about your customers."
        },
    ];
    
    // const [messages, setMessages] = useLocalStorage("messages",defaultMessage);
    const [messages, setMessages] = useState(defaultMessage);
    const [typingIndicator, setTypingIndicator] = useState(false);
    const [ghostMessage, setGhostMessage] = useState(false);
    const [lastUserMessage, setLastUserMessage] = useState('');
    const [stopActivated, setStopActivated] = useState(false);
    const [startStreaming, setStartStreaming] = useState(false);

    const stopGenerating = () => {
        setTypingIndicator(false);
        setStopActivated(true)
    };

    function handleClearState() {
        !ghostMessage ? setMessages(defaultMessage) : null;
    };

    function handleSend(input) {
        if (input.trim() !== "") {
            // Prepare new state values
            setLastUserMessage(input)
        
            // Update state
            setMessages([...messages, { type: "user", text: input }]);
            setTypingIndicator(true);
            setGhostMessage(true)
    
            // Get new AI message
            setStartStreaming(true)
        }
    }    

    // Thumbs Feedback ----------------------------

    const [feedback, setFeedback] = useLocalStorage("feedback",{});

    async function handleFeedback(feedbackValue, messageId ) {
        switchFeedbackOpen(true, messageId, true)
        setFeedback((prevFeedback) => {
            const updatedFeedback = { ...prevFeedback };

            if (!updatedFeedback[messageId]) {
                updatedFeedback[messageId] = 0 ; 
            }

            updatedFeedback[messageId] = feedbackValue;

            return updatedFeedback;
        });
        try {
            const response = await axios.post(API_PREFIX+BASE_CHATBOT_URL+"/feedback", {"message_id": messageId, "feedback": feedbackValue, "salesagentcd": salesAgentCD, "oktaid": oktaId});
        } catch (error) {
            console.error("Error in feedback post:", error);
        }
    }

    // Written Feedback ----------------------------

    const [feedbackOpen, setFeedbackOpen] = useLocalStorage("feedbackOpen",{});
    const [writeFeedback, setWriteFeedback] = useState('');
    const [writeAnswer, setWriteAnswer] = useState('');
  
    function switchFeedbackOpen(newVal, messageId, resetvalue ) {
        if (newVal != feedbackOpen[messageId]) {
            setWriteFeedback('')
            setWriteAnswer('')
        }
        if (resetvalue) {
            setWriteFeedback('')
            setWriteAnswer('')
        }
        if (feedbackOpen[messageId] =='done') {
            return
        }
        setFeedbackOpen((prevFeedback) => {
            const updatedFeedback = { ...prevFeedback };
            updatedFeedback[messageId] = newVal;
            return updatedFeedback;
        });
    }

    async function handleWrittenFeedback(messageId) {
        if (writeFeedback.trim() !== "" || writeAnswer.trim() !== "" ) {
            switchFeedbackOpen(-1, messageId, true)
            try {
                const response = await axios.post(API_PREFIX+BASE_CHATBOT_URL+"/feedback_message", {"message_id": messageId, "feedback": writeFeedback ,"salesagentcd": salesAgentCD, "oktaid": oktaId});
            } catch (error) {
                console.error("Error in feedback_message:", error);
            } 
        }
    }

    return (
        <ChatContext.Provider
        value={{
            messages, setMessages, typingIndicator, stopGenerating, ghostMessage, 
            handleClearState,stopActivated, setStopActivated,
            setGhostMessage, setTypingIndicator,lastUserMessage, setLastUserMessage,
            BASE_CHATBOT_URL, handleSend, startStreaming, setStartStreaming,
            handleFeedback, feedback, handleWrittenFeedback, feedbackOpen, setFeedbackOpen, writeFeedback,
            setWriteFeedback, switchFeedbackOpen, chatSessionId, isMobile, salesAgentCD,oktaId,
            outputText, setOutputText, generateEmail, emailLoading, emailFeedback, setEmailFeedback,handleEmailFeedback
        }}
        >
        {children}
        </ChatContext.Provider>
    );
};

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'next-themes';
import logo from 'img/logo.png';
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarSection,
  SidebarSpacer,
  SidebarItem,
  SidebarLabel,
} from 'components/catalyst/sidebar';
import { Divider } from 'components/catalyst/divider';
import { menuItems } from './MenuItems';
import { AccountSelect } from './AccountSelect';
import { UserDropdown } from './UserDropdown';

export function SidebarContent({ closeSidebar }) {
  const { theme } = useTheme(); // Get the current theme

  return (
    <Sidebar>
      <SidebarHeader>
        <SidebarSection>
          <Link to="/" onClick={closeSidebar}>
            <img
              src={logo}
              className={`w-32 mx-auto py-2 ${theme === 'dark' ? 'invert' : ''}`} // Invert the logo in dark mode
              alt="Logo"
            />
          </Link>
        </SidebarSection>
      </SidebarHeader>

      <SidebarBody>
        <SidebarSection>
          {menuItems.map((item) => (
            <SidebarItem as={Link} to={item.path} key={item.path} onClick={closeSidebar}>
              <item.icon />
              <SidebarLabel>{item.label}</SidebarLabel>
            </SidebarItem>
          ))}
          <Divider />
        </SidebarSection>
        <AccountSelect />
        <SidebarSpacer />
      </SidebarBody>
      <SidebarFooter className="max-lg:hidden">
        <UserDropdown closeSidebar={closeSidebar} />
      </SidebarFooter>
    </Sidebar>
  );
}

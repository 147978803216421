import React,{ useContext, useEffect } from 'react';
import { CustomerProfile } from './CustomerProfile';
import { KPIs } from './KPIs';
import { Insights } from './Insights';
import { ConnectButtons, CustomerDetails } from './CustomerDetails';
import { MinimumSpendPotential } from './MinimumSpendPotential';
import { CustomerReminders } from './Reminders';
import { Purchases } from './Purchases';
import { Notes } from './Notes';
import { SendEmail } from './SendEmail';
import { CustomerSalesTable } from './SalesTable';
import { DataContext } from 'contexts/DataContext';
import { AppContext } from 'contexts/AppContext';
import { NotesContext } from 'contexts/NotesContext';
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card';
import { ScrollArea } from 'components/ui/scroll-area';
import { Rebate } from './Rebate';

export default function CustomerTemplate() {

  const { customerInsights, custcd } = useContext(DataContext);
  const { logEvent } = useContext(AppContext);
  
  useEffect(() => {
    logEvent('Customer Details', 'Page Viewed', null);
  }, []);

  return (
    <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10">

      <div className="flex flex-wrap w-full pt-4">
        <div className="w-full xl:w-1/2 xl:pr-4 space-y-4">
          <KPIs />
          <CustomerProfile />
          <MinimumSpendPotential />
          {/* <Rebate /> */}
          <CustomerDetails />
          <Purchases />
        </div>
        <div className="w-full space-y-4 xl:w-1/2">
          <CustomerSalesTable />
          <Insights />
          <CustomerReminders/>
          <Notes/>
        </div>
      </div>
    </div>
  );
}